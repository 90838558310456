<template>
  <div class="propertyTypeAdd">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content bgFFF">
      <div class="commonF padding20">
        <h2 class="title">
          <div class="title_icon"></div>
          {{ isEdit ? "编辑" : "添加" }}物品类型
        </h2>
        <!--搜索条件区域-->
        <div class="">
          <el-form
            ref="propertyForm"
            :inline="false"
            label-position="right"
            :model="formInline"
            class="demo-form-inline"
            label-width="100px"
            :rules="rules"
          >
            <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
              <el-select
                v-model.trim="formInline.operationId"
                filterable
                size="15"
                style="width: 200px"
                :disabled="isEdit"
              >
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in tenantList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类型名称" prop="assetsTypeName">
              <el-input
                maxlength="20"
                v-model.trim="formInline.assetsTypeName"
                placeholder="请输入类型名称"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.remarks')">
              <el-input
                :maxlength="50"
                v-model="formInline.remarks"
                placeholder="请输入备注"
                type="textarea"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-button type="primary" @click="submit()" :loading="loading"
              >提交</el-button
            >
            <el-button
              type="primary"
              @click="$router.go('-1')"
              :loading="loading"
              >取消</el-button
            >
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rules: {
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "blur",
          },
        ],
        assetsTypeName: [
          {
            required: true,
            message: "请输入类型名称",
            trigger: "blur",
          },
        ],
      },
      tenantList: [],
      loading: false,
      formInline: {
        assetsTypeName: "",
        remarks: "",
        operationId: "", // 商户id
      },
      isEdit: false,
    };
  },
  methods: {
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    submit() {
      this.$refs.propertyForm.validate((valid) => {
        if (valid) {
          let opt = {};
          let url = "";
          if (this.$route.query.assetsTypeId) {
            url = `/acb/2.0/assetsType/update`;
            opt.assetsTypeId = this.formInline.assetsTypeId;
            opt.assetsTypeName = this.formInline.assetsTypeName;
            opt.remarks = this.formInline.remarks;
            opt.operationId = this.formInline.operationId;
          } else {
            url = `/acb/2.0/assetsType/add`;
            opt.assetsTypeName = this.formInline.assetsTypeName;
            opt.remarks = this.formInline.remarks;
            opt.operationId = this.formInline.operationId;
          }
          this.$axios
            .post(url, {
              data: {
                ...opt,
                type: 0,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$router.go("-1");
              } else {
                this.$alert(res.desc, "提示");
              }
            });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getTenantList();
    if (this.$route.query.assetsTypeId) {
      this.isEdit = true;
      this.formInline = this.$route.query;
    }
  },
};
</script>
<style lang="stylus" scoped>
.propertyTypeAdd {
  .breadcrumb {
    height: 30px;
  }

  .content {
    overflow: hidden;
    border: 1px solid #c0ccda;
    border-radius: 4px;
    padding: 20px;

    .searchWrapper {
      padding-left: 30px;
    }
  }
}
</style>
